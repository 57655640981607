// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-plasmic-pages-features-jsx": () => import("./../../.plasmic/pages/features.jsx" /* webpackChunkName: "component---cache-plasmic-pages-features-jsx" */),
  "component---cache-plasmic-pages-home-jsx": () => import("./../../.plasmic/pages/home.jsx" /* webpackChunkName: "component---cache-plasmic-pages-home-jsx" */),
  "component---cache-plasmic-pages-pricing-jsx": () => import("./../../.plasmic/pages/pricing.jsx" /* webpackChunkName: "component---cache-plasmic-pages-pricing-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

